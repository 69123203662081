import React, { useCallback, useEffect, useRef } from 'react';
import { Box } from '@heycater/design-system';
import dynamic from 'next/dynamic';
import styled from 'styled-components';

import { useQualification } from 'qualification/context';
import { QUALIFICATION_STATUS } from 'qualification/context/model';
import EmbeddedQuestion from 'shared/components/EmbeddedFunnel/EmbeddedQuestion';
import EmbeddedQuestionLayout from 'shared/components/EmbeddedFunnel/variationA/EmbeddedQuestionLayout';
import { redirectToMarketplace } from 'shared/helpers/urls';
export const stepTitleMappingVariationA = {
  catering_categories:
    'qualification:embeddedFunnel.cateringCategories.variationA.title',
  city: 'qualification:embeddedFunnel.city.title',
  dietary_restrictions:
    'qualification:embeddedFunnel.dietaryRestrictions.title',
  event_date: 'qualification:embeddedFunnel.eventDate.title',
  people_count_and_budget: 'qualification:embeddedFunnel.budgetAndPeople.title',
  customer_tier: 'qualification:customerTier.title',
  rare_customer_tier: 'qualification:rareCustomerTier.title',
};
const EmbeddedRequestPage = dynamic(() =>
  import('shared/components/EmbeddedFunnel/EmbeddedRequestPage')
);
import LoadingIndicator from 'shared/components/EmbeddedFunnel/LoadingIndicator';

// eslint-disable-next-line react/display-name
const EmbeddedFunnel = React.forwardRef(
  (
    {
      onBackground,
      highlightColor,
      ratings,
      slides,
      renderTitle,
      titleTranslationKeys = stepTitleMappingVariationA,
      LayoutComponent = EmbeddedQuestionLayout,
    },
    ref
  ) => {
    const scrollHelperRef = useRef(null);

    const onQuestionChange = useCallback(() => {
      if (scrollHelperRef && scrollHelperRef?.current) {
        scrollHelperRef.current.scrollIntoView({
          inline: 'nearest',
          block: 'nearest',
          behavior: 'smooth',
        });
      }
    }, []);

    const { state, actions, canContinue } = useQualification({
      onQuestionChange,
    });

    useEffect(() => {
      if (scrollHelperRef && scrollHelperRef?.current) {
        scrollHelperRef.current.scrollIntoView({
          inline: 'nearest',
          block: 'nearest',
          behavior: 'smooth',
        });
      }
    }, [state.status]);

    const toNextStep = useCallback(() => {
      actions.nextStep();
    }, [actions]);

    useEffect(() => {
      if (state.status === QUALIFICATION_STATUS.qualifiedForMarketplace) {
        redirectToMarketplace(state.marketplaceUrl);
      }
    }, [state]);

    if (
      state.status !== QUALIFICATION_STATUS.answered &&
      state.status !== QUALIFICATION_STATUS.answering &&
      state.status !== QUALIFICATION_STATUS.qualifiedForRequest
    ) {
      return null;
    }

    const stepId =
      (state.status === 'answering' && state.qualification.step?.id) || '';

    const onKeyPress = (event) => {
      if (event.key === 'Enter' && canContinue) {
        event.preventDefault();
        toNextStep();
      }
    };

    if (state.status === QUALIFICATION_STATUS.qualifiedForRequest) {
      return <EmbeddedRequestPage ratings={ratings} />;
    }

    let textColor = onBackground === 'light' ? 'dark' : 'light';

    if (stepId.includes('customer-') && slides?.customerTier?.whiteWrapper) {
      textColor = 'dark';
    }

    if (stepId === 'city' && slides?.city?.whiteWrapper) {
      textColor = 'dark';
    }

    if (stepId === 'catering_categories' && slides?.category?.whiteWrapper) {
      textColor = 'dark';
    }

    if (
      stepId === 'people_count_and_budget' &&
      slides?.budgetAndPeople?.whiteWrapper
    ) {
      textColor = 'dark';
    }

    if (
      stepId === 'dietary_restrictions' &&
      slides?.dietaryRestriction?.whiteWrapper
    ) {
      textColor = 'dark';
    }

    if (stepId === 'event_date' && slides?.dateAndLead?.whiteWrapper) {
      textColor = 'dark';
    }

    return (
      <FormWrapper
        $textColor={textColor}
        tabIndex={0}
        ref={ref}
        data-embedded-funnel
        name={
          'step' in state.qualification
            ? state.qualification.step.id
            : state.status
        }
        onSubmit={(e) => {
          e.preventDefault();
          toNextStep();
        }}
        onKeyPressCapture={onKeyPress}
      >
        <Box position="absolute" top={'-120px'} ref={scrollHelperRef} />
        <Box width="100%">
          {state.status === QUALIFICATION_STATUS.answering && (
            <EmbeddedQuestion
              slides={slides}
              step={state.qualification.step}
              highlightColor={highlightColor}
              ratings={ratings}
              renderTitle={renderTitle}
              LayoutComponent={LayoutComponent}
              titleTranslationKeys={titleTranslationKeys}
            />
          )}
          <Box pt={3} />
          {state.status === QUALIFICATION_STATUS.answered && (
            <>
              <LoadingIndicator
                variant={onBackground === 'dark' ? 'light' : 'dark'}
              />
              <Box display="none">
                {/* include and hide the request page. Reason: to trigger loading it's files sooner, since it's a dynamic component, and would have a delay othervise */}
                <EmbeddedRequestPage ratings={ratings} />
              </Box>
            </>
          )}
        </Box>
      </FormWrapper>
    );
  }
);

const FormWrapper = styled.form`
  --embedded-text-color: ${({ $textColor }) =>
    $textColor === 'light' ? 'white' : 'rgb(60, 67, 64)'};
  width: 100%;
  position: relative;

  :focus,
  :focus-visible {
    outline: none;
  }
`;

export default EmbeddedFunnel;
