import React, { useEffect, useState } from 'react';
import { GetStaticProps } from 'next';

import { EXPERIMENT, isVariationActive } from 'lib/ablyft';
import { fetchArticles } from 'lib/contentful/hub';
import { fetchHomePage } from 'lib/contentful/landingPages';
import { fetchTestimonials } from 'lib/contentful/testimonials';
import { useSyncABTestInQueryParam } from 'qualification/hooks/useSyncABTestInQueryParam';
import { fetchRatings } from 'shared/services/trustedShops/fetchRatings';
import Homepage from 'static_pages/home';
import { HomepageProps } from 'static_pages/home/model';
import HomePageVariationA from 'static_pages/home/variationA';
import HomePageVariationB from 'static_pages/home/variationB';

function Home(props: HomepageProps) {
  const [pageToRender, setPageToRender] = useState<string | null>(null);

  useEffect(() => {
    if (isVariationActive(EXPERIMENT.homePage.variations.original)) {
      setPageToRender('original');
    } else if (isVariationActive(EXPERIMENT.homePage.variations.variationA)) {
      setPageToRender('variationA');
    } else if (isVariationActive(EXPERIMENT.homePage.variations.variationB)) {
      setPageToRender('variationB');
    }
  }, []);

  useSyncABTestInQueryParam(EXPERIMENT.homepageRedesign);

  if (pageToRender === 'variationA') {
    return <HomePageVariationA {...props} />;
  } else if (pageToRender === 'variationB') {
    return <HomePageVariationB {...props} />;
  } else {
    return <Homepage {...props} />;
  }
}

export const getStaticProps: GetStaticProps<HomepageProps> = async ({
  locale,
}) => {
  const [homePage, articles, ratings, testimonials] = await Promise.all([
    fetchHomePage({ locale }),
    fetchArticles({ locale, order: '-sys.createdAt', limit: 3 }),
    fetchRatings(),
    fetchTestimonials({ locale }),
  ]);

  const articlesWithoutCircularReferences = articles.map((article) => {
    delete article.fields.suggestedArticles;
    return article;
  });

  if (!homePage) {
    return {
      notFound: true,
    };
  }

  const props: HomepageProps = {
    ...homePage,
    testimonials,
    ratings,
    articles: articlesWithoutCircularReferences,
    hasEnglishVersion: true,
  };
  return {
    props,
  };
};

export default Home;
