import React from 'react';
import { Box, Typography } from '@heycater/design-system';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useBreakpointDown } from 'qualification/hooks/useBreakpoints';

export default function Benefits() {
  const { t } = useTranslation('homepage');
  const mobile = useBreakpointDown('sm');

  if (mobile)
    return (
      <MobileWrapper>
        <Box display="flex" justifyContent="center" mb={4}>
          <TileContainer>
            <Title>{t('benefits.title')}</Title>
          </TileContainer>
        </Box>
        <FlexRow mb={2}>
          <Paper>
            <TileContainer>
              <Title>{t('benefits.benefit1.number')}</Title>
              <Subtitle>{t('benefits.benefit1.text')}</Subtitle>
            </TileContainer>
          </Paper>
          <Paper>
            <TileContainer>
              <Title>{t('benefits.benefit2.number')}</Title>
              <Subtitle>{t('benefits.benefit2.text')}</Subtitle>
            </TileContainer>
          </Paper>
        </FlexRow>
        <Box mt={3}>
          <FlexRow>
            <Paper>
              <TileContainer>
                <Title>{t('benefits.benefit3.number')}</Title>
                <Subtitle>{t('benefits.benefit3.text')}</Subtitle>
              </TileContainer>
            </Paper>
            <Paper>
              <TileContainer>
                <Title>{t('benefits.benefit4.number')}</Title>
                <Subtitle>{t('benefits.benefit4.text')}</Subtitle>
              </TileContainer>
            </Paper>
          </FlexRow>
        </Box>
      </MobileWrapper>
    );
  return (
    <Wrapper>
      <Container>
        <TileContainer>
          <Title>{t('benefits.title')}</Title>
        </TileContainer>
        <VerticalDivider />
        <TileContainer>
          <Title>{t('benefits.benefit1.number')}</Title>
          <Subtitle>{t('benefits.benefit1.text')}</Subtitle>
        </TileContainer>
        <TileContainer>
          <Title>{t('benefits.benefit2.number')}</Title>
          <Subtitle>{t('benefits.benefit2.text')}</Subtitle>
        </TileContainer>
        <TileContainer>
          <Title>{t('benefits.benefit3.number')}</Title>
          <Subtitle>{t('benefits.benefit3.text')}</Subtitle>
        </TileContainer>
        <TileContainer>
          <Title>{t('benefits.benefit4.number')}</Title>
          <Subtitle>{t('benefits.benefit4.text')}</Subtitle>
        </TileContainer>
      </Container>
    </Wrapper>
  );
}

const Paper = styled.div`
  background: #ffffff;
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  width: 165px;
  padding: ${({ theme }) => theme.spacing(1)}px;
`;

const MobileWrapper = styled.div`
  background: #f8f8f8;
  padding: ${({ theme }) => theme.spacing(3, 2)};
`;

const FlexRow = styled(Box)`
  display: flex;
  width: 343px;
  justify-content: space-between;
  margin: 0 auto;
`;

const Title = styled(Typography)`
  font-weight: 700;
  font-size: 18px;
  line-height: 33.6px;
  text-align: center;
  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 20px;
  }
`;

const Subtitle = styled(Typography)`
  font-weight: 400;
  font-size: 12px;
  line-height: 26.88px;
  text-align: center;
  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 16px;
  }
`;

const VerticalDivider = styled(Box)`
  border-left: 1px solid #d0d0d0;
`;

const TileContainer = styled.div`
  max-width: 200px;
`;

const Wrapper = styled.div`
  background: #f8f8f8;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  width: 1074px;
  margin: auto;
  padding: ${({ theme }) => theme.spacing(3, 0)};
`;
