import React from 'react';
import styled from 'styled-components';

import FlaconiLogoMb from '@images/homepage//logos/flaconi.svg';
import AirbnbLogo from '@images/homepage/logos/airbnb.svg';
import BainLogo from '@images/homepage/logos/bain.svg';
import DoctolibLogo from '@images/homepage/logos/doctolib.svg';
import EyLogo from '@images/homepage/logos/ey.svg';
import FlaconiLogo from '@images/homepage/logos/flaconi.svg';
import GetyourguideLogo from '@images/homepage/logos/getyourguide.svg';
import JllLogo from '@images/homepage/logos/jll.svg';
import MetaLogo from '@images/homepage/logos/meta.svg';
import AirbnbLogoMb from '@images/homepage/logos/mobile/airbnb.svg';
import BainLogoMb from '@images/homepage/logos/mobile/bain.svg';
import DoctolibLogoMb from '@images/homepage/logos/mobile/doctolib.svg';
import EyLogoMb from '@images/homepage/logos/mobile/ey.svg';
import GetyourguideLogoMb from '@images/homepage/logos/mobile/getyourguide.svg';
import JllLogoMb from '@images/homepage/logos/mobile/jll.svg';
import MetaLogoMb from '@images/homepage/logos/mobile/meta.svg';
import N26LogoMb from '@images/homepage/logos/mobile/n26.svg';
import SpotifyLogoMb from '@images/homepage/logos/mobile/spotify.svg';
import SumupLogoMb from '@images/homepage/logos/mobile/sumup.svg';
import TaxfixLogoMb from '@images/homepage/logos/mobile/taxfix.svg';
import TradeLogoMb from '@images/homepage/logos/mobile/traderepublic.svg';
import N26Logo from '@images/homepage/logos/n26.svg';
import SpotifyLogo from '@images/homepage/logos/spotify.svg';
import SumupLogo from '@images/homepage/logos/sumup.svg';
import TaxfixLogo from '@images/homepage/logos/taxfix.svg';
import TradeLogo from '@images/homepage/logos/traderepublic.svg';
import { useBreakpointDown } from 'qualification/hooks/useBreakpoints';

export default function CompanyLogos() {
  const mobile = useBreakpointDown('sm');
  if (mobile) {
    return (
      <Container>
        <Wrapper>
          <TaxfixLogoMb />
          <SumupLogoMb />
          <MetaLogoMb />
          <EyLogoMb />
          <N26LogoMb />
          <SpotifyLogoMb />
          <TradeLogoMb />
          <FlaconiLogoMb />
          <AirbnbLogoMb />
          <JllLogoMb />
          <GetyourguideLogoMb />
          <BainLogoMb />
          <DoctolibLogoMb />
        </Wrapper>
      </Container>
    );
  }

  return (
    <Container>
      <Wrapper>
        <TaxfixLogo />
        <SumupLogo />
        <MetaLogo />
        <EyLogo />
        <N26Logo />
        <SpotifyLogo />
        <TradeLogo />
        <FlaconiLogo />
        <AirbnbLogo />
        <JllLogo />
        <GetyourguideLogo />
        <BainLogo />
        <DoctolibLogo />
      </Wrapper>
    </Container>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1118px;
  margin: auto;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing(2)}px;
  padding: ${({ theme }) => theme.spacing(0, 2)};
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 0;
  }
`;

const Container = styled.div`
  background: #fff;
  padding: ${({ theme }) => theme.spacing(3, 0)};
  display: flex;
  flex-wrap: wrap;
`;
