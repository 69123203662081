import React from 'react';
import { Box } from '@heycater/design-system';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { withQualificationProvider } from 'qualification/context';
import Navigation from 'shared/layouts/Navigation';
import Hero from 'static_pages/home/components/Hero';
import { HomepageProps } from 'static_pages/home/model';
import Benefits from 'static_pages/home/variationB/components/Benefits';
import Footer from 'static_pages/home/variationB/components/Footer';

const CardSelections = dynamic(
  () => import('static_pages/home/variationB/components/CardSelections')
);
const WhyHeycater = dynamic(
  () => import('static_pages/home/variationB/components/WhyHeycater')
);
const Testimonials = dynamic(
  () => import('static_pages/home/variationB/components/Testimonials')
);
const WeAreHeycater = dynamic(
  () => import('static_pages/home/variationB/components/WeAreHeycater')
);
const RightCatering = dynamic(
  () => import('static_pages/home/variationB/components/RightCatering')
);
const Inspirations = dynamic(
  () => import('static_pages/home/variationB/components/Inspirations')
);
const CompareSection = dynamic(
  () => import('static_pages/home/variationB/components/CompareSection')
);
const Cities = dynamic(
  () => import('static_pages/home/variationB/components/Cities')
);
const FAQ = dynamic(
  () => import('static_pages/home/variationB/components/FAQ')
);
const CateringsSection = dynamic(
  () => import('static_pages/home/variationB/components/CateringsSection')
);
const BannerImage1 = dynamic(
  () => import('static_pages/home/variationB/components/BannerImage1')
);
const BannerImage2 = dynamic(
  () => import('static_pages/home/variationB/components/BannerImage2')
);

function HomePageVariationB({ ratings }: HomepageProps) {
  const { t } = useTranslation('static_pages');

  return (
    <>
      <Head>
        <title>{t('homepage.variationA.meta.title')}</title>
        <meta
          name="description"
          content={t('homepage.variationA.meta.description')}
        />
      </Head>
      <Navigation
        ratings={ratings}
        hideNavigationLinks={false}
        renderCTA={false}
      />
      <BodyWrapper>
        <Box id="qf-start" overflow="hidden">
          <Hero ratings={ratings} />
          <Benefits />
        </Box>
        <CardSelections />
        <BannerImage1 />
        <WhyHeycater />
        <Testimonials />
        <BannerImage2 />
        <WeAreHeycater />
        <RightCatering />
        <Inspirations />
        <CompareSection />
        <Cities />
        <FAQ />
        <CateringsSection />
        <Footer />
      </BodyWrapper>
    </>
  );
}

export default withQualificationProvider(
  {
    storage: null,
    initialized: true,
  },
  HomePageVariationB
);

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    gap: 77px;
  }
`;
