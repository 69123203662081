import React, { useCallback } from 'react';
import { Box, Button } from '@heycater/design-system';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { EXPERIMENT, isVariationActive } from 'lib/ablyft';
import { useQualification } from 'qualification/context';
import { QUALIFICATION_STATUS } from 'qualification/context/model';
import { steps as allSteps } from 'qualification/schema/steps';
import { StepId } from 'qualification/schema/steps';
import ProgressIndicator from 'static_pages/home/components/Hero/ProgressIndicator';
import Container from 'system/components/Container';

interface Props {
  onBackground: 'dark' | 'light';
}

const namedNextButtonKey: Record<StepId, string> = {
  catering_categories: 'qualification:embeddedFunnel.cateringCategories.nextTo',
  city: 'qualification:embeddedFunnel.city.nextTo',
  dietary_restrictions:
    'qualification:embeddedFunnel.dietaryRestrictions.nextTo',
  event_date: 'qualification:embeddedFunnel.eventDate.nextTo',
  people_count_and_budget: 'qualification:embeddedFunnel.budget.nextTo',
  customer_tier: 'qualification:embeddedFunnel.customerTier.nextTo',
  rare_customer_tier: 'qualification:embeddedFunnel.rareCustomerTier.nextTo',
};

const EmbeddedQuestionControls = ({ onBackground }: Props) => {
  const { state, actions, canContinue } = useQualification();

  const toPrevStep = useCallback(() => {
    actions.prevStep();
  }, []);

  const { t } = useTranslation();

  if (
    state.status !== QUALIFICATION_STATUS.answered &&
    state.status !== QUALIFICATION_STATUS.answering &&
    state.status !== QUALIFICATION_STATUS.qualifiedForRequest
  ) {
    return null;
  }

  const step =
    state.status === QUALIFICATION_STATUS.answering
      ? state.qualification.step
      : null;
  const steps = state.qualification.steps;

  const stepIndex = step ? steps.findIndex(({ id }) => id === step.id) : -1;
  const prevStep = steps[stepIndex - 1];

  const isLastStep = Boolean(step && stepIndex === steps.length - 1);

  const enabledSteps = state.qualification.steps.filter((q) => !q.disabled);

  const hideNextBtn = (() => {
    if (state.status !== 'answering') return false;

    const customerTierABCExperiment =
      isVariationActive(EXPERIMENT.customerTierABC.variations.variationB) ||
      isVariationActive(EXPERIMENT.customerTierABC.variations.variationC);
    if (
      customerTierABCExperiment &&
      state.qualification.step.id === allSteps.rareCustomerTierStep.id
    )
      return true;
    return false;
  })();
  const nextStep = steps[stepIndex + 1];

  return (
    <>
      <Container size="sm" display="flex" flexDirection="column">
        <Box
          display={{ xs: 'none', sm: 'block' }}
          visibility={
            state.status === QUALIFICATION_STATUS.answered
              ? 'hidden'
              : 'visible'
          }
          mb={{ xs: 2 }}
        >
          <ProgressIndicator
            currentStep={
              state.status === QUALIFICATION_STATUS.answering
                ? state.qualification.stepIndex + 1
                : enabledSteps.length
            }
            stepCount={enabledSteps.length}
          />
        </Box>
        <ButtonsWrapper
          visibility={
            state.status === QUALIFICATION_STATUS.answered
              ? 'hidden'
              : 'visible'
          }
        >
          {prevStep && !prevStep.disabled && (
            <EmbeddedButton
              id="embedded-funnel__prev-btn"
              variant={onBackground === 'dark' ? 'tertiary' : 'secondary'}
              rounded
              onClick={toPrevStep}
            >
              {t('common:labels.back')}
            </EmbeddedButton>
          )}
          {hideNextBtn ? null : (
            <Box>
              <EmbeddedButton
                variant="primary"
                type="submit"
                id="embedded-funnel__next-btn"
                disabled={!canContinue}
                rounded
              >
                {isLastStep
                  ? t('qualification:lastQuestionCTAShort')
                  : t(namedNextButtonKey[nextStep.id])}
              </EmbeddedButton>
            </Box>
          )}
        </ButtonsWrapper>
      </Container>
    </>
  );
};

const EmbeddedButton = styled(Button)`
  padding: ${({ theme }) => theme.spacing(1, 3)};
`;

const ButtonsWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(2)}px;
`;

export default EmbeddedQuestionControls;
