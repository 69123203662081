import React from 'react';
import { Typography } from '@heycater/design-system';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Step1Icon from '@images/shared/qf/variationA/step1.png';
import Step2Icon from '@images/shared/qf/variationA/step2.png';
import Step3Icon from '@images/shared/qf/variationA/step3.png';
import Step4Icon from '@images/shared/qf/variationA/step4.png';

export default function Steps() {
  const { t } = useTranslation('static_pages');

  return (
    <StepsWrapper>
      <StepContainer>
        <Image src={Step1Icon} height={29} width={29} quality={100} alt="" />
        <Typography>{t('homepage.variationA.steps.step1')}</Typography>
      </StepContainer>
      <StepContainer>
        <Image src={Step2Icon} height={29} width={29} quality={100} alt="" />
        <Typography>{t('homepage.variationA.steps.step2')}</Typography>
      </StepContainer>
      <StepContainer>
        <Image src={Step3Icon} height={29} width={29} quality={100} alt="" />
        <Typography>{t('homepage.variationA.steps.step3')}</Typography>
      </StepContainer>
      <StepContainer>
        <Image src={Step4Icon} height={29} width={29} quality={100} alt="" />
        <Typography>{t('homepage.variationA.steps.step4')}</Typography>
      </StepContainer>
    </StepsWrapper>
  );
}

const StepContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-direction: column;
  p {
    text-align: center;
    font-size: 10px;
    ${({ theme }) => theme.breakpoints.up('md')} {
      font-size: 16px;
      text-align: left;
    }
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-direction: row;
  }
`;

const StepsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 1050px;
  margin: auto;
  padding: 0 16px;
  padding-bottom: 16px;
  gap: 8px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 0;
    gap: 56px;
  }
`;
