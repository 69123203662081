import React from 'react';
import { Box, Typography } from '@heycater/design-system';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import FlexBox from 'shared/components/FlexBox';
import { BENEFITS } from 'shared/constants';
import Ratings from 'static_pages/home/components/Hero/Ratings';
import TrustedCustomers from 'static_pages/home/components/Hero/TrustedCustomers';
import Container from 'system/components/Container';

interface Props {
  rating: number;
}

const HeroBenefits = ({ rating }: Props) => {
  const { t } = useTranslation('common');
  return (
    <Box
      bgcolor={'#F8FBE5'}
      display="flex"
      alignItems="center"
      height={{ xs: 'auto', lg: '93px' }}
      width="100%"
    >
      <Container size="sm">
        <Box
          display="flex"
          flexDirection={{ xs: 'column', lg: 'row' }}
          alignItems="center"
          justifyContent="center"
        >
          <FlexBox
            flexDirection={{ xs: 'column', lg: 'row' }}
            justifyContent="center"
            alignItems="center"
            ml={0}
            style={{
              margin: 0,
            }}
          >
            <CustomerCountText
              style={{
                margin: 0,
              }}
              textAlign="center"
              component="span"
            >
              {t('benefits.customerCount', {
                count: BENEFITS.CUSTOMER_COUNT,
              })}
            </CustomerCountText>
            <TrustedCustomers />
          </FlexBox>
          <Divider />
          <FlexBox ml={3} flexDirection={{ xs: 'column', lg: 'row' }}>
            <Ratings rating={rating} />
          </FlexBox>
        </Box>
      </Container>
    </Box>
  );
};

const Divider = styled.div`
  width: 90vw;
  height: 1px;
  background-color: #d0d0d0;
  margin-top: ${({ theme }) => theme.spacing(2)}px;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    width: 1px;
    margin-top: 0px;
    height: 70px;
  }
`;

const CustomerCountText = styled(Typography)`
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: #565b58;
  padding-top: ${({ theme }) => theme.spacing(3)}px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 18px;
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    padding-top: 0px;
    margin-right: ${({ theme }) => theme.spacing(1)}px;
  }
`;

export default HeroBenefits;
